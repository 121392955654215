<template>
    <div class="dashboard__block wall__item wall__notification">
        <div v-if="data.view === 'user'" class="notification__item">
            <div class="notification__icon notification__icon--user"/>
            <div class="notification__wrap">
                <div class="notification__header">
                    <h2 class="h2 notification__title">Новый сотрудник</h2>
                    <span
                        class="notification__date"
                        :title="$moment(data.created_at).format('LLL')"
                    >{{ data.created_at | moment('from', dates) }}</span>
                </div>
                <div class="notification__text">
                    В компании появился новый сотрудник - <router-link class="notification__link" :to="data.title_poll">{{data.title}}</router-link>!
                    <span v-html="data.content"></span>
                </div>
            </div>
        </div>
        <comments
            v-if="data.view === 'user' && data.comments"
            :comments="data.comments"
            :obj-id="data.id"
            content-type="wall"
        />
        <div v-if="data.view === 'incentive'" class="notification__item">
            <div class="notification__icon notification__icon--incentive"/>
            <div class="notification__wrap">
                <div class="notification__header">
                    <h2 class="h2 notification__title">Премия</h2>
                    <span
                        class="notification__date"
                        :title="$moment(data.created_at).format('LLL')"
                    >{{ data.created_at | moment('from', dates) }}</span>
                </div>
                <div class="notification__text">
                    {{data.title}}
                </div>
            </div>
        </div>

        <div v-if="data.view === 'law_break'" class="notification__item">
            <div class="notification__icon notification__icon--law-break"/>
            <div class="notification__wrap">
                <div class="notification__header-2">
                    <h2 class="h2 notification__title">Штраф</h2>
                    <span
                        class="notification__date"
                        :title="$moment(data.created_at).format('LLL')"
                    >{{ data.created_at | moment('from', dates) }}</span>
                </div>
                <div class="notification__text">
                    {{data.title}}
                </div>
            </div>
        </div>


    </div>
</template>

<script>
    import {mapState} from "vuex";

    export default {
        name: 'Notification',
        components: {
            Comments : () => import('../Comments/Comments')
        },
        computed: {
            ...mapState('default_data', [
                'time',
            ]),
        },
        created: function () {
            this.dates = new Date().toLocaleString("en-US", {timeZone: this.time.time_zone, hour12: false }).replace(',', '')
        },
        data() {
            return {
                dates: null
            }
        },
        props: {
            data: {
                type: Object,
                required: true
            }
        }
    }
</script>

<style lang="sass" scoped>
    @import "#sass/v-style"

    .comments
        margin-top: 0

    .notification
        &__item
            padding: 30px
            display: flex

        &__header
            display: flex
            flex-direction: column

        &__title
            font-size: 1.125rem
            font-weight: 500

        &__date
            color: #A4AEBD

        &__wrap
            display: flex
            flex-direction: column
            justify-content: space-between
            margin-left: 20px
            width: 100%

        &__text
            font-size: 1rem
        &__link
            color: $link-main

        &__icon
            min-width: 60px
            min-height: 60px
            width: 60px
            height: 60px
            background-size: contain
            background-repeat: no-repeat

            &--material
                background-image: $material-icon

            &--user
                background-image: $user-icon

            &--law-break
                background-image: $law-break-icon

            &--incentive
                background-image: $incentive-icon

    @media (max-width: 768px)
        .notification
            &__icon
                height: 40px
                width: 40px
                min-height: 40px
                min-width: 40px

</style>
